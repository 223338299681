<script setup>
import { ref } from "vue";
import { useUserStore } from "@/stores/user";
import useAuth from "@/hooks/useAuth";
import gravatarUrl from "gravatar-url";

const userStore = useUserStore();
const menu = ref(false);
const { signOut } = useAuth();

const closeMenuAndSignOut = () => {
  menu.value = false;
  signOut();
};
</script>

<template>
  <div class="text-center" v-if="userStore.user">
    <v-menu v-model="menu" :close-on-content-click="true" location="bottom">
      <template v-slot:activator="{ props }">
        <v-list density="compact" class="bg-appbar">
          <v-list-item
            :prepend-avatar="
              gravatarUrl(userStore.user.email, {
                size: 26,
                default: 'retro',
              })
            "
            :title="userStore?.user?.name"
            v-bind="props"
            :subtitle="`${userStore.user.tenant.name}`"
          >
            <template v-slot:append> </template>
          </v-list-item>
        </v-list>
      </template>

      <v-card min-width="300">
        <v-list>
          <v-list-item
            :to="`/users/${userStore.user.id}`"
            prepend-icon="mdi-account"
            title="My Profile"
            exact
          >
          </v-list-item>
          <v-list-item
            :to="`/users/${userStore.user.id}/edit`"
            prepend-icon="mdi-account-edit"
            title="Edit Profile"
            exact
          >
          </v-list-item>
          <v-list-item
            @click.prevent="closeMenuAndSignOut"
            prepend-icon="mdi-logout"
            title="Sign Out"
            exact
          >
          </v-list-item>
        </v-list>

        <!-- <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn variant="text" @click="menu = false"> Cancel </v-btn>
                    <v-btn color="primary" variant="text" @click="menu = false">
                      Save
                    </v-btn>
                  </v-card-actions> -->
      </v-card>
    </v-menu>
  </div>
</template>
