import { ref, reactive, Ref } from "vue"
import c3api from "../c3api"
import { useRouter } from "vue-router/auto"
import { useToast } from "vue-toastification"
import { useUserStore } from "../stores/user"
// import useAutologout from "./useAutoLogout"
interface LocalState {
  email: string | null
  phone: string | null
  password: string
  tab: "email" | "phone"
}

export default function useAuth() {
  const loading: Ref<boolean> = ref(false)
  const userStore = useUserStore()
  const toast = useToast()
  const router = useRouter()
  const defaultLocalState: LocalState = reactive({
    email: null,
    phone: null,
    password: "",
    tab: "email",
  })

  // const {trackUserActivity, clearTrackers} = useAutologout();

  const signIn = async (
    localState: LocalState = defaultLocalState,
  ): Promise<void> => {
    loading.value = true

    try {
      const response = await c3api.post("/auth/sign_in", localState)
      let user = response.data.data
      if (user && user.token) {
        localStorage.setItem("token", user.token)
        userStore.fetchUser()
        router.push("/dashboard")
        if(user.enforce_v3_login_timeout) {
            // trackUserActivity(user.v3_login_timeout_ms);
        }
      } else {
        toast.error("You do not have permission to access this application.")
      }
    } catch (error) {
      // Adjust according to your error handling strategy
      let errMsg = "Unknown connection error occurred."
      if (error?.response) {
        errMsg = error.response.data.data.join(". ")
      }
      toast.error(errMsg)
    } finally {
      loading.value = false
    }
  }

  const signOut = async (): Promise<void> => {
    // clearTrackers();
    userStore.logout()
    router.push("/")
  }

  const formatPhoneNumber = (value: string): string => {
    let cleaned = ("" + value).replace(/\D/g, "")
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`
    }
    return value
  }

  return {
    loading,
    signIn,
    signOut,
    formatPhoneNumber,
  }
}
