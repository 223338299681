import { defineStore } from 'pinia';

export const useSidebarStore = defineStore('rail', {
  state: () => ({
    showRail: true,
  }),
  actions: {
    collapse(state: boolean) {
      this.showRail = state;
    },
  },
});
