// @ts-ignore
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  NavigationGuardNext,
} from "vue-router/auto";
import { useUserStore } from "./stores/user";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (!Object.prototype.hasOwnProperty.call(to.meta, "requiresAuth")) {
      to.meta.requiresAuth = true;
    }
    if (!Object.prototype.hasOwnProperty.call(to.meta, "requiresManagerAuth")) {
      to.meta.requiresManagerAuth = false;
    }
    const requiresAuth = to.meta.requiresAuth as boolean;
    const requiresManagerAuth = to.meta.requiresManagerAuth as boolean;
    const requiresTicketAdmin = to.meta.requiresTicketAdmin as boolean;
    const requiresTicketSuperadmin = to.meta.requiresTicketSuperadmin as boolean;
    const requiresTicketTenantAdmin = to.meta
      .requiresTicketTenantAdmin as boolean;
    const userStore = useUserStore();

    // Check if the token is present in the route's query parameters
    const tokenParam = to.query.auth_token as string | undefined;

    if (tokenParam) {
      // reset the token in local storage & reset user store
      localStorage.setItem("token", tokenParam);
    }

    if (localStorage.getItem("token")) {
      await userStore.fetchUser();
    }

    const isAuthenticated = !!localStorage.getItem("token") && !!userStore.user;
    if (requiresAuth && !isAuthenticated) {
      next({ name: "/" }); // Redirect to home if not authenticated
      return;
    }

    if (requiresManagerAuth && !userStore.user?.employee_profile?.is_manager) {
      next({ name: "/403" }); // Redirect to home if not a manager
      return;
    }

    if (requiresTicketAdmin && !userStore.isSupportTicketAdmin()) {
      next({ name: "/403" }); // Redirect to home if not a support ticket admin
      return;
    }

    if (requiresTicketSuperadmin && !userStore.hasSupportTicketRole("superadmin")) {
      next({ name: "/403" }); // Redirect to home if not a global support ticket admin
      return;
    }
    if (requiresTicketTenantAdmin && !userStore.isSupportTicketAdmin()) {
      next({ name: "/403" }); // Redirect to home if not a tenant support ticket admin
      return;
    }

    next(); // Proceed with the navigation
  }
);

export default router;
