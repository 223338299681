import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

// Ensure that VITE_API_BASE_URL is typed in your .d.ts file
const c3api: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL + '/v1',
  timeout: 20000,
});

c3api.interceptors.request.use(
  (config: any) => {
    // Do something before request is sent
    const token = localStorage.getItem('token');
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error: AxiosError) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

c3api.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with response data
    return response;
  },
  (error: AxiosError) => {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default c3api;
