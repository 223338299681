<script setup lang="ts">
import { onMounted, reactive, ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import pageTitle, { setPageTitle } from "@/utils/pageTitle";
import useEdiRequests from "@/hooks/useEdiRequests";
import { useLoadingStore } from "@/stores/loading";
import { definePage } from "vue-router/auto";
import EdiRequestChart from "@/components/edi/EdiRequestChart.vue";
import LoadingProgress from "@/components/LoadingProgress.vue";
import { orderModelToPrefix, orderUrlConnect3 } from "@/utils/orderUtils";
import { debounce } from "lodash";
import Pending945 from "@/components/edi/Pending945.vue";

definePage({
  meta: {
    requiresManagerAuth: true,
  },
});

const userStore = useUserStore();
const {
  ediRequests,
  fetchEdiRequests,
  retryEdiRequest,
  markComplete,
  updatePage,
  workflowStates,
  requestCodes,
  stateClass,
  retryAllEdiRequest,
} = useEdiRequests();
const selectedStates = ref(["pending", "failed", "retrying"]);
const selectedRequestCodes = ref([940, 943, 944, 945]);
const defaultState = { page: 1, searchText: null, filters: [], sort: "id" };
const localState = reactive(
  localStorage.getItem("ediFilter")
    ? JSON.parse(localStorage.getItem("ediFilter") ?? "")
    : defaultState
);
const loadingStore = useLoadingStore();
const chartKey = ref(0);
const statsVisible = ref(
  localStorage.getItem("ediStatsVisible")
    ? JSON.parse(localStorage.getItem("ediStatsVisible") ?? "")
    : true
);
const poNumber = ref(null);

// Create a debounced version of fetchEdiRequests
const debouncedFetchEdiRequests = debounce((state) => {
  fetchEdiRequests(state);
}, 700);

onMounted(async () => {
  applyStateFilters();
  applyRequestCodeFilters();
  setPageTitle("EDI Requests");
  const savedState = JSON.parse(localStorage.getItem("ediFilters") ?? "");
  if (savedState) {
    Object.assign(localState, savedState);
    // Update selectedStates based on loaded state
    selectedStates.value =
      localState.filters
        .filter(
          (f: { column: string; condition: string }) =>
            f.column === "workflow_state" && f.condition === "in"
        )
        .flatMap((f: { value: any }) => f.value) || [];
    // Update selectedRequestCodes based on loaded state
    selectedRequestCodes.value =
      localState.filters
        .filter(
          (f: { column: string; condition: string }) =>
            f.column === "request_code" && f.condition === "in"
        )
        .flatMap((f: { value: any }) => f.value) || [];
  }

  debouncedFetchEdiRequests(localState);
});

watch(
  localState,
  () => {
    localStorage.setItem("ediFilters", JSON.stringify(localState));
    debouncedFetchEdiRequests(localState);
  },
  { deep: true }
);

watch(selectedStates, (newValue) => {
  applyStateFilters();
});

watch(selectedRequestCodes, (newValue) => {
  applyRequestCodeFilters();
});

watch(statsVisible, (newValue) => {
  localStorage.setItem("ediStatsVisible", JSON.stringify(newValue));
});

const applyStateFilters = async () => {
  localState.filters = localState.filters.filter(
    (filter: { column: string }) => filter.column !== "workflow_state"
  );

  if (selectedStates.value && selectedStates.value.length > 0) {
    localState.filters.push({
      condition: "in",
      column: "workflow_state",
      value: selectedStates.value,
    });
  }
  localState.page = 1;
  debouncedFetchEdiRequests(localState);
};

const applyRequestCodeFilters = async () => {
  localState.filters = localState.filters.filter(
    (filter: { column: string }) => filter.column !== "request_code"
  );

  if (selectedRequestCodes.value && selectedRequestCodes.value.length > 0) {
    localState.filters.push({
      condition: "in",
      column: "request_code",
      value: selectedRequestCodes.value,
    });
  }
  localState.page = 1;
  debouncedFetchEdiRequests(localState);
};

const reloadEdi = () => {
  localState.page = 1;
  debouncedFetchEdiRequests(localState);
  chartKey.value++;
};

const codeBadgeColor = (code: number) => {
  if (code === 940) {
    return "primary";
  } else if (code === 943) {
    return "indigo";
  } else if (code === 944) {
    return "pink";
  } else if (code === 945) {
    return "blue";
  } else {
    return "grey";
  }
};

const retryAllEdi = () => {
  localState.page = 1;
  retryAllEdiRequest(localState);
};

const applyPoFilter = () => {
  localState.filters = localState.filters.filter(
    (filter: { column: string }) => filter.column !== "po_number"
  );

  if (poNumber.value && poNumber.value.length > 0) {
    localState.filters.push({
      condition: "like",
      column: "po_number",
      value: poNumber.value,
    });
  }
  localState.page = 1;
  debouncedFetchEdiRequests(localState);
};
</script>

<template>
  <div v-if="userStore.user">
    <v-card-title class="d-flex justify-space-between"
      ><span>{{ pageTitle }}</span>
    </v-card-title>
    <v-card-subtitle>Use this section to manage EDI requests. </v-card-subtitle>
    <v-card flat class="mx-4 mt-4" style="max-height: 350px; overflow-y: hidden">
      <div class="d-flex">
        <div class="w-50">
          <v-card-title
            class="grid grid-cols-2 align-center justify-space-between w-100"
            @click.stop="statsVisible = true"
            ><span>Stats</span></v-card-title
          >
          <EdiRequestChart v-if="statsVisible" :reloadKey="chartKey" />
        </div>
        <div class="w-50 h-100" style="overflow-y: scroll; max-height: 350px">
          <v-card-title class="grid grid-cols-2 align-center justify-space-between w-100"
            ><span v-if="statsVisible">Pending 945s</span
            ><v-btn
              size="small"
              variant="text"
              class="justify-self-end float-end float-right align-self-end self-end"
              @click.stop="statsVisible = !statsVisible"
              >{{ statsVisible ? "Collapse Stats" : "View Stats" }}</v-btn
            ></v-card-title
          >
          <Pending945 v-if="statsVisible" />
        </div>
      </div>
    </v-card>
    <v-card flat class="mx-4 mt-4">
      <div class="d-flex justify-space-between">
        <v-select
          label="Filter by Code"
          v-model="selectedRequestCodes"
          :items="requestCodes"
          class="mr-1"
          multiple
          chips
          clearable
        />
        <v-select
          label="Filter by Status"
          v-model="selectedStates"
          :items="workflowStates"
          item-text="title"
          item-value="value"
          class="mr-1"
          multiple
          chips
          clearable
        />
        <v-text-field
          label="Filter by PO Number"
          v-model="poNumber"
          @input="applyPoFilter"
          clearable
        />
        <v-btn square flat icon="mdi-reload" class="mt-1" @click="reloadEdi"></v-btn>
        <v-btn variant="tonal" class="mt-3 mr-2" @click="retryAllEdi">RETRY ALL</v-btn>
      </div>
      <v-table>
        <thead class="bg-surface-light text-black text-uppercase">
          <tr>
            <th class="text-kef">Type</th>
            <th class="text-kef">ID</th>
            <th class="text-left">Client</th>
            <th class="text-left">Order</th>
            <th class="text-left">PO</th>
            <th class="text-left">State</th>
            <th class="text-left">Payload</th>
            <th class="text-left">Errors</th>
            <th class="text-left">Created</th>
            <th class="text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-if="ediRequests && ediRequests.data.length != 0 && !loadingStore.isLoading"
            v-for="request in ediRequests.data"
            :key="request.id"
          >
            <td>
              <v-chip class="mr-2" :color="codeBadgeColor(request.request_code)">{{
                request.request_code
              }}</v-chip>
            </td>
            <td>
              {{ request.id }}
            </td>

            <td class="d-flex flex-wrap py-2">
              <div class="w-100">{{ request.client.name }}</div>
              <v-chip size="x-small">{{ request?.tenant?.name }}</v-chip>
            </td>
            <td>
              <a
                :href="orderUrlConnect3(request?.order_id, request?.order_type)"
                target="_blank"
                class="link"
                v-if="request?.order_id"
              >
                {{ orderModelToPrefix(request?.order_type) }}-{{ request?.order?.id }}
              </a>
            </td>
            <td>
              <a
                :href="orderUrlConnect3(request?.order_id, request?.order_type)"
                target="_blank"
                class="link"
                v-if="request?.order_id"
              >
                {{ request?.order?.po_number }}
              </a>
            </td>
            <td>
              <v-chip variant="text" :class="stateClass(request.workflow_state)">
                {{ request.workflow_state }}
              </v-chip>
            </td>
            <td>
              <v-dialog width="600">
                <template v-slot:activator="{ props }">
                  <v-btn size="small" variant="plain" v-bind="props" text="View Paylad" />
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card title="Request Payload">
                    <v-card-text>
                      <vue-json-pretty :data="request.payload" />
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn text="Close" @click="isActive.value = false"></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </td>
            <td>
              <v-dialog width="600">
                <template v-slot:activator="{ props }">
                  <v-btn
                    size="small"
                    variant="plain"
                    v-bind="props"
                    text="View Errors"
                    v-if="Object.keys(request.request_errors).length"
                  />
                </template>

                <template v-slot:default="{ isActive }">
                  <v-card flat title="Errors">
                    <v-card-text>
                      <vue-json-pretty :data="request.request_errors" />
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text="Close" @click="isActive.value = false"></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </td>

            <td>{{ new Date(request.created_at).toLocaleString() }}</td>
            <td>
              <v-btn
                v-if="request.workflow_state != 'completed'"
                color="grey-lighten-2"
                elevation="0"
                icon="mdi-reload"
                size="small"
                @click="retryEdiRequest(request.id, localState)"
              />
              <v-btn
                v-if="
                  request.workflow_state != 'completed' &&
                  userStore.user?.employee_profile?.is_manager
                "
                class="ml-1"
                elevation="0"
                color="green-lighten-1"
                icon="mdi-check"
                size="small"
                @click="markComplete(request.id, localState)"
              />
            </td>
          </tr>
          <tr v-else>
            <td colspan="12" class="text-center">No data to show</td>
          </tr>
        </tbody>
      </v-table>

      <LoadingProgress v-if="loadingStore.isLoading" />
    </v-card>
    <div class="text-center mt-4" v-if="ediRequests && ediRequests.total_pages > 1">
      <v-pagination
        active-color="primary"
        color="grey-darken-1"
        v-model="localState.page"
        :length="ediRequests.total_pages"
        @update:modelValue="updatePage"
        rounded="circle"
      ></v-pagination>
    </div>
  </div>
</template>
