import { s } from "vite/dist/node/types.d-aGj9QkWt"

const primaryColor = "black"
const secondaryColor = "#555"
const primaryTextColor = "#DDD"
const secondaryTextColor = "#a2a2b4"
const unreadBackGroundColor = "#869F9F"
const primaryButtonBackGroundColor = unreadBackGroundColor
const secondaryButtonBackGroundColor = "#C6DFCD"
const dropdownBorderStyle = "1px solid #333"
const tabLabelAfterStyle = "#AFE1AF !important"

const styles = {
  layout: {
    root: {
      margin: "5px 12px 12px 12px",
    },
  },
  bellButton: {
    // root: {
    //   paddingLeft: "6px",
    //   paddingTop: "0",
    //   paddingBottom: "4px",
    //   minWidth: "55px",
    //   svg: {
    //     color: "#FFF !important",
    //     // fill: "",
    //   },
    // },
    dot: {
      rect: {
        stroke: "#FFF",
        //     fill: "#F00",
        strokeWidth: "2px",
        width: "13px",
        height: "13px",
        x: 0,
        //     y: 2,
      },
    },
  },
  unseenBadge: {
    root: { color: "#F00", background: secondaryColor },
  },
  popover: {
    root: { zIndex: -99, margin: "10px" },
    arrow: {
      // backgroundColor: primaryColor,
      // borderColor: secondaryColor,
      root: {
        backgroundColor: "transparent",
        borderColor: "transparent",
      },
    },
    dropdown: {
      // border: dropdownBorderStyle,
      // borderRadius: "10px",
    },
  },
  header: {
    root: {
      // backgroundColor: primaryColor,
      // "&:hover": { backgroundColor: primaryColor },
      // cursor: "pointer",
      // color: primaryTextColor,
    },
    cog: { opacity: 1 },
    markAsRead: {
      color: primaryTextColor,
      fontSize: "14px",
    },
    title: { color: primaryTextColor },
    backButton: {
      color: primaryTextColor,
    },
  },
  loader: {
    root: {
      stroke: primaryTextColor,
    },
  },
  accordion: {
    item: {
      backgroundColor: secondaryColor,
      ":hover": {
        backgroundColor: secondaryColor,
      },
    },
    content: {
      backgroundColor: secondaryColor,
      borderBottomLeftRadius: "7px",
      borderBottomRightRadius: "7px",
    },
    control: {
      ":hover": {
        backgroundColor: secondaryColor,
      },
      color: primaryTextColor,
      title: {
        color: primaryTextColor,
      },
    },
    chevron: {
      color: primaryTextColor,
    },
  },
  switch: {
    root: {},
    input: {
      ":checked~label": {
        background: primaryColor,
        ".mantine-Switch-trackLabel": {
          backgroundColor: primaryColor,
        },
      },
      ":not(checked)~label": {
        background: primaryTextColor,
        ".mantine-Switch-trackLabel": {
          backgroundColor: primaryTextColor,
        },
      },
    },
    track: {},
    thumb: { backgroundColor: secondaryColor },
  },
  notifications: {
    root: {
      ".nc-notifications-list-item": {
        backgroundColor: secondaryColor,
      },
    },
    listItem: {
      layout: {
        borderRadius: "7px",
        color: primaryTextColor,
        ".mantine-Avatar-root": {
          width: "20px",
          height: "20px",
          minWidth: "20px",
        },
        ".mantine-Avatar-image": {
          width: "20px",
          height: "20px",
          minWidth: "20px",
        },
        "div:has(> .mantine-Avatar-root)": {
          border: "none",
          width: "20px",
          height: "20px",
          minWidth: "20px",
        },
      },
      timestamp: { color: secondaryTextColor, fontWeight: "bold" },
      dotsButton: {
        path: {
          fill: primaryTextColor,
        },
      },
      unread: {
        "::before": { background: unreadBackGroundColor },
      },
      buttons: {
        primary: {
          background: primaryButtonBackGroundColor,
          color: primaryTextColor,
          "&:hover": {
            background: primaryButtonBackGroundColor,
            color: secondaryTextColor,
          },
        },
        secondary: {
          background: secondaryButtonBackGroundColor,
          color: secondaryTextColor,
          "&:hover": {
            background: secondaryButtonBackGroundColor,
            color: secondaryTextColor,
          },
        },
      },
    },
  },
  actionsMenu: {
    item: { "&:hover": { backgroundColor: secondaryColor } },
    dropdown: {
      backgroundColor: primaryColor,
    },
    arrow: {
      backgroundColor: primaryColor,
      borderTop: "0",
      borderLeft: "0",
    },
  },
  preferences: {
    item: {
      title: { color: primaryTextColor },
      divider: { borderTopColor: primaryColor },
      channels: { color: secondaryTextColor },
      content: {
        icon: { color: primaryTextColor },
        channelLabel: { color: primaryTextColor },
        success: { color: primaryTextColor },
      },
    },
  },
  tabs: {
    tabLabel: {
      color: primaryTextColor,
      "::after": { background: tabLabelAfterStyle },
      ":hover": { color: tabLabelAfterStyle },
      "[data-active]": { color: tabLabelAfterStyle },
    },
    tabsList: { borderBottomColor: primaryColor },
  },
}

const dark = {
  ...styles,
  layout: {
    root: { background: "#333" },
  },
}

const light = {
  ...styles,
  // layout: {}
}

export default { dark, light }
