<script setup lang="ts">
import { onMounted, watch, reactive, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { useRouter, definePage } from "vue-router/auto";
import pageTitle, { setPageTitle } from "@/utils/pageTitle";
import { shortDateWithTimeTz } from "@/utils/dateUtils";
import useSupportTickets from "@/hooks/useSupportTickets";
import useUsers from "@/hooks/useUsers";
import { useDisplay } from "vuetify";
import gravatarUrl from "gravatar-url";
import LoadingProgress from "@/components/LoadingProgress.vue";

definePage({
  meta: {
    requiresTicketAdmin: true,
  },
});

interface LocalState {
  page: number;
  page_size: number;
  searchText: string;
  filters: Filter[] | null;
  sort: string;
}

interface Filter {
  column: string;
  condition: string;
  value: string | null;
}

const {
  updatePage,
  fetchSupportTicketAdmins,
  supportTicketAdmins,
  updateSupportTicketAdmins,
  fetchSupportTicketUsers,
  supportTicketUsers,
} = useSupportTickets();
const userStore = useUserStore();
const router = useRouter();
const newAdminDialog = ref(false);
const adminType = ref(null);
const adminTypes = ref([{ text: "Admin", value: "tenant" }]);
const newAdminState: LocalState = reactive({
  page: 1,
  page_size: 100,
  searchText: "",
  filters: [],
  sort: "id",
});
const localState: LocalState = reactive({
  page: 1,
  page_size: 100,
  searchText: "",
  filters: [],
  sort: "id",
});

onMounted(async () => {
  setPageTitle("Support Ticket Settings");
  await fetchSupportTicketAdmins(localState);
  await getAdminTypes();
});

const handleRemoveAdmin = async (user: User) => {
  const confirmed = confirm(
    `Are you sure you want to remove ${user.name} as a support ticket admin?`
  );
  if (confirmed) {
    await updateSupportTicketAdmins(user, false);
    await fetchSupportTicketAdmins(localState);
  }
};

const handleAddAdmin = async (user: User) => {
  const confirmed = confirm(
    `Are you sure you want to add ${user.name} as a ${adminType.value} support ticket admin?`
  );
  if (confirmed) {
    await updateSupportTicketAdmins(user, true, adminType.value);
    await fetchSupportTicketAdmins(localState);
  }
};

watch(
  () => newAdminState.searchText,
  async (newVal, _) => {
    if (newVal && newVal.length > 0) {
      newAdminState.page = 1;
      newAdminState.filters = [
        {
          column: "name",
          condition: "like",
          value: newVal,
        },
      ];
    } else {
      newAdminState.filters = [];
    }
  },
  { immediate: true }
);

watch(newAdminState, async () => {
  await fetchSupportTicketUsers(newAdminState);
});

const getAdminTypes = async () => {
  if (userStore.hasSupportTicketRole("superadmin")) {
    adminTypes.value.push({
      text: "Superadmin",
      value: "global",
    });
  }
};

const adminChipColor = (isHovering: boolean | undefined, user: User) => {
  if (user.permissions.support_ticket_role == "superadmin") {
    return isHovering ? "indigo-darken-4" : "primary";
  } else {
    return isHovering ? "grey-darken-3" : "grey-darken-1";
  }
};
</script>

<template>
  <div v-if="userStore.user">
    <!-- Title and New Ticket Button -->
    <v-row class="mb-4">
      <v-col>
        <v-card-title class="d-flex justify-space-between flex-wrap lg:flex-nowrap">
          <span>{{ pageTitle }}</span>
        </v-card-title>
        <v-card-subtitle
          >Use this section to manage support tickets settings.</v-card-subtitle
        >
      </v-col>
    </v-row>
  </div>
  <v-card flat class="mx-4 mt-4">
    <div class="d-flex justify-space-between align-center">
      <div>
        <v-card-title>Ticket Admins</v-card-title>
        <v-card-subtitle>These users can view and respond to any ticket.</v-card-subtitle>
      </div>

      <div class="pr-4">
        <v-btn color="primary" @click="newAdminDialog = true">New Admin</v-btn>
      </div>
    </div>

    <v-card-text>
      <div class="d-flex align-center flex-wrap">
        <v-hover
          v-if="supportTicketAdmins"
          v-for="user in supportTicketAdmins.data"
          :key="user.id"
        >
          <template v-slot:default="{ isHovering, props }">
            <div
              class="d-flex align-center my-1 mx-2"
              @dblclick="router.push(`/users/${user.id}`)"
              :key="user.id"
            >
              <div class="d-flex align-center">
                <v-chip v-bind="props" :color="adminChipColor(isHovering, user)">
                  <v-avatar
                    :image="
                      gravatarUrl(user.email, {
                        size: 32,
                        default: 'retro',
                      })
                    "
                    size="24"
                    color="surface-variant"
                    class="mr-2"
                  ></v-avatar>
                  <span class="text-no-wrap mr-1">{{ user.name }} </span>
                  <small>
                    {{
                      user.permissions.support_ticket_role == "superadmin"
                        ? "Superadmin "
                        : ""
                    }}
                    {{
                      user.permissions.support_ticket_role == "tenant_admin"
                        ? "Tenant Admin "
                        : ""
                    }}
                  </small>

                  <v-icon
                    @click="handleRemoveAdmin(user)"
                    size="small"
                    class="ml-2 cursor-pointer"
                    :color="
                      user.permissions.support_ticket_role == 'superadmin'
                        ? 'primary'
                        : 'accent'
                    "
                    >mdi-close</v-icon
                  >
                </v-chip>
              </div>
            </div>
          </template>
        </v-hover>
      </div>
      <LoadingProgess v-if="!supportTicketAdmins" />
    </v-card-text>
  </v-card>
  <div
    class="text-center mt-4"
    v-if="supportTicketAdmins && supportTicketAdmins.total_pages > 1"
  >
    <v-pagination
      v-model="localState.page"
      :length="supportTicketAdmins.total_pages"
      @update:modelValue="updatePage"
      rounded="circle"
    ></v-pagination>
  </div>

  <v-dialog v-model="newAdminDialog" width="50%">
    <v-card title="New Support Ticket Admin">
      <v-card-text>
        <div>
          <v-select
            v-model="adminType"
            :items="adminTypes"
            item-title="text"
            item-value="value"
            label="Admin Type"
          />
        </div>
        <v-text-field
          v-if="adminType"
          v-model="newAdminState.searchText"
          label="Search users by name"
          prepend-inner-icon="mdi-text-box-search-outline"
          flat
        ></v-text-field>
        <div class="mb-4" v-if="supportTicketUsers && adminType">
          <v-hover v-for="(user, i) in supportTicketUsers.data" :key="i">
            <template v-slot:default="{ isHovering, props }">
              <v-row class="bg-grey-lighten-4 mx-1">
                <v-col
                  v-bind="props"
                  @click="handleAddAdmin(user)"
                  :class="`d-flex align-center py-2 my-1 px-2 rounded text-body-1 ${
                    isHovering ? 'bg-primary text-white cursor-pointer' : ''
                  }`"
                >
                  {{ user.name }}
                  <small class="ml-2">({{ user.email }}) / {{ user.tenant.name }}</small>
                </v-col>
              </v-row>
            </template>
          </v-hover>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="newAdminDialog = false">Close Dialog</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
