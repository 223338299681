import _definePage_default_0 from '/app/src/pages/index.vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/app/src/pages/edi-requests/index.vue?definePage&vue'
import _definePage_default_3 from '/app/src/pages/reset-password/index.vue?definePage&vue'
import _definePage_default_4 from '/app/src/pages/reset-password/[token].vue?definePage&vue'
import _definePage_default_5 from '/app/src/pages/support-tickets/settings.vue?definePage&vue'
import _definePage_default_6 from '/app/src/pages/users/[id]/index.vue?definePage&vue'
import _definePage_default_7 from '/app/src/pages/users/admins.vue?definePage&vue'
import _definePage_default_8 from '/app/src/pages/warehouses/[id]/dashboard.vue?definePage&vue'

export const routes = [
  _mergeRouteRecord(
  {
    path: '/',
    name: '/',
    component: () => import('/app/src/pages/index.vue'),
    /* no children */
  },
  _definePage_default_0
  ),
  {
    path: '/403',
    name: '/403',
    component: () => import('/app/src/pages/403.vue'),
    /* no children */
  },
  {
    path: '/404',
    name: '/404',
    component: () => import('/app/src/pages/404.vue'),
    /* no children */
  },
  {
    path: '/billings',
    /* internal name: '/billings' */
    /* no component */
    children: [
      {
        path: '',
        name: '/billings/',
        component: () => import('/app/src/pages/billings/index.vue'),
        /* no children */
      },
      {
        path: 'BillingOrders',
        name: '/billings/BillingOrders',
        component: () => import('/app/src/pages/billings/BillingOrders.vue'),
        /* no children */
      },
      {
        path: 'default-billing-rates',
        /* internal name: '/billings/default-billing-rates' */
        /* no component */
        children: [
          {
            path: '',
            name: '/billings/default-billing-rates/',
            component: () => import('/app/src/pages/billings/default-billing-rates/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            /* internal name: '/billings/default-billing-rates/[id]' */
            /* no component */
            children: [
              {
                path: 'edit',
                name: '/billings/default-billing-rates/[id]/edit',
                component: () => import('/app/src/pages/billings/default-billing-rates/[id]/edit.vue'),
                /* no children */
              },
              {
                path: 'view',
                name: '/billings/default-billing-rates/[id]/view',
                component: () => import('/app/src/pages/billings/default-billing-rates/[id]/view.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/bins',
    /* internal name: '/bins' */
    /* no component */
    children: [
      {
        path: '',
        name: '/bins/',
        component: () => import('/app/src/pages/bins/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/bins/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/bins/[id]/',
            component: () => import('/app/src/pages/bins/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'edit',
            name: '/bins/[id]/edit',
            component: () => import('/app/src/pages/bins/[id]/edit.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'Bins',
        name: '/bins/Bins',
        component: () => import('/app/src/pages/bins/Bins.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/bins/new',
        component: () => import('/app/src/pages/bins/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/carriers',
    /* internal name: '/carriers' */
    /* no component */
    children: [
      {
        path: '',
        name: '/carriers/',
        component: () => import('/app/src/pages/carriers/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/carriers/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/carriers/[id]/',
            component: () => import('/app/src/pages/carriers/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'available-orders',
            name: '/carriers/[id]/available-orders',
            component: () => import('/app/src/pages/carriers/[id]/available-orders.vue'),
            /* no children */
          },
          {
            path: 'pending-orders',
            name: '/carriers/[id]/pending-orders',
            component: () => import('/app/src/pages/carriers/[id]/pending-orders.vue'),
            /* no children */
          },
          {
            path: 'users',
            name: '/carriers/[id]/users',
            component: () => import('/app/src/pages/carriers/[id]/users.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'Carriers',
        name: '/carriers/Carriers',
        component: () => import('/app/src/pages/carriers/Carriers.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/carriers/new',
        component: () => import('/app/src/pages/carriers/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/changelogs',
    /* internal name: '/changelogs' */
    /* no component */
    children: [
      {
        path: '',
        name: '/changelogs/',
        component: () => import('/app/src/pages/changelogs/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/changelogs/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/changelogs/[id]/',
            component: () => import('/app/src/pages/changelogs/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'edit',
            name: '/changelogs/[id]/edit',
            component: () => import('/app/src/pages/changelogs/[id]/edit.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'new',
        name: '/changelogs/new',
        component: () => import('/app/src/pages/changelogs/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/clients',
    /* internal name: '/clients' */
    /* no component */
    children: [
      {
        path: '',
        name: '/clients/',
        component: () => import('/app/src/pages/clients/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/clients/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/clients/[id]/',
            component: () => import('/app/src/pages/clients/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'developer',
            name: '/clients/[id]/developer',
            component: () => import('/app/src/pages/clients/[id]/developer.vue'),
            /* no children */
          },
          {
            path: 'integrations',
            name: '/clients/[id]/integrations',
            component: () => import('/app/src/pages/clients/[id]/integrations.vue'),
            /* no children */
          },
          {
            path: 'notes',
            name: '/clients/[id]/notes',
            component: () => import('/app/src/pages/clients/[id]/notes.vue'),
            /* no children */
          },
          {
            path: 'notifications',
            name: '/clients/[id]/notifications',
            component: () => import('/app/src/pages/clients/[id]/notifications.vue'),
            /* no children */
          },
          {
            path: 'rates',
            name: '/clients/[id]/rates',
            component: () => import('/app/src/pages/clients/[id]/rates.vue'),
            /* no children */
          },
          {
            path: 'settings',
            name: '/clients/[id]/settings',
            component: () => import('/app/src/pages/clients/[id]/settings.vue'),
            /* no children */
          },
          {
            path: 'users',
            name: '/clients/[id]/users',
            component: () => import('/app/src/pages/clients/[id]/users.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'Clients',
        name: '/clients/Clients',
        component: () => import('/app/src/pages/clients/Clients.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/clients/new',
        component: () => import('/app/src/pages/clients/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/csvupload',
    /* internal name: '/csvupload' */
    /* no component */
    children: [
      {
        path: '',
        name: '/csvupload/',
        component: () => import('/app/src/pages/csvupload/index.vue'),
        /* no children */
      },
      {
        path: 'CsvUploader',
        name: '/csvupload/CsvUploader',
        component: () => import('/app/src/pages/csvupload/CsvUploader.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/damage-reports',
    /* internal name: '/damage-reports' */
    /* no component */
    children: [
      {
        path: '',
        name: '/damage-reports/',
        component: () => import('/app/src/pages/damage-reports/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/damage-reports/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/damage-reports/[id]/',
            component: () => import('/app/src/pages/damage-reports/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'photos',
            name: '/damage-reports/[id]/photos',
            component: () => import('/app/src/pages/damage-reports/[id]/photos.vue'),
            /* no children */
          },
          {
            path: 'transactions',
            name: '/damage-reports/[id]/transactions',
            component: () => import('/app/src/pages/damage-reports/[id]/transactions.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'new',
        name: '/damage-reports/new',
        component: () => import('/app/src/pages/damage-reports/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: () => import('/app/src/pages/dashboard.vue'),
    /* no children */
  },
  {
    path: '/ecommerce',
    /* internal name: '/ecommerce' */
    /* no component */
    children: [
      {
        path: '',
        name: '/ecommerce/',
        component: () => import('/app/src/pages/ecommerce/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/edi-requests',
    /* internal name: '/edi-requests' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/edi-requests/',
        component: () => import('/app/src/pages/edi-requests/index.vue'),
        /* no children */
      },
  _definePage_default_2
  )
    ],
  },
  {
    path: '/inbound-orders',
    /* internal name: '/inbound-orders' */
    /* no component */
    children: [
      {
        path: '',
        name: '/inbound-orders/',
        component: () => import('/app/src/pages/inbound-orders/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/inbound-orders/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/inbound-orders/[id]/',
            component: () => import('/app/src/pages/inbound-orders/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'appointments',
            name: '/inbound-orders/[id]/appointments',
            component: () => import('/app/src/pages/inbound-orders/[id]/appointments.vue'),
            /* no children */
          },
          {
            path: 'documents',
            name: '/inbound-orders/[id]/documents',
            component: () => import('/app/src/pages/inbound-orders/[id]/documents.vue'),
            /* no children */
          },
          {
            path: 'history',
            name: '/inbound-orders/[id]/history',
            component: () => import('/app/src/pages/inbound-orders/[id]/history.vue'),
            /* no children */
          },
          {
            path: 'inventory-transactions',
            name: '/inbound-orders/[id]/inventory-transactions',
            component: () => import('/app/src/pages/inbound-orders/[id]/inventory-transactions.vue'),
            /* no children */
          },
          {
            path: 'lp-transactions',
            name: '/inbound-orders/[id]/lp-transactions',
            component: () => import('/app/src/pages/inbound-orders/[id]/lp-transactions.vue'),
            /* no children */
          },
          {
            path: 'pallets',
            name: '/inbound-orders/[id]/pallets',
            component: () => import('/app/src/pages/inbound-orders/[id]/pallets.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'new',
        name: '/inbound-orders/new',
        component: () => import('/app/src/pages/inbound-orders/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/invoices',
    /* internal name: '/invoices' */
    /* no component */
    children: [
      {
        path: '',
        name: '/invoices/',
        component: () => import('/app/src/pages/invoices/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/invoices/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/invoices/[id]/',
            component: () => import('/app/src/pages/invoices/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'InvoiceDetails',
            name: '/invoices/[id]/InvoiceDetails',
            component: () => import('/app/src/pages/invoices/[id]/InvoiceDetails.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'InvoicesList',
        name: '/invoices/InvoicesList',
        component: () => import('/app/src/pages/invoices/InvoicesList.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/outbound-orders',
    /* internal name: '/outbound-orders' */
    /* no component */
    children: [
      {
        path: '',
        name: '/outbound-orders/',
        component: () => import('/app/src/pages/outbound-orders/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/outbound-orders/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/outbound-orders/[id]/',
            component: () => import('/app/src/pages/outbound-orders/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'appointments',
            name: '/outbound-orders/[id]/appointments',
            component: () => import('/app/src/pages/outbound-orders/[id]/appointments.vue'),
            /* no children */
          },
          {
            path: 'documents',
            name: '/outbound-orders/[id]/documents',
            component: () => import('/app/src/pages/outbound-orders/[id]/documents.vue'),
            /* no children */
          },
          {
            path: 'history',
            name: '/outbound-orders/[id]/history',
            component: () => import('/app/src/pages/outbound-orders/[id]/history.vue'),
            /* no children */
          },
          {
            path: 'inventory-transactions',
            name: '/outbound-orders/[id]/inventory-transactions',
            component: () => import('/app/src/pages/outbound-orders/[id]/inventory-transactions.vue'),
            /* no children */
          },
          {
            path: 'lp-transactions',
            name: '/outbound-orders/[id]/lp-transactions',
            component: () => import('/app/src/pages/outbound-orders/[id]/lp-transactions.vue'),
            /* no children */
          },
          {
            path: 'shipments',
            name: '/outbound-orders/[id]/shipments',
            component: () => import('/app/src/pages/outbound-orders/[id]/shipments.vue'),
            /* no children */
          },
          {
            path: 'transport',
            name: '/outbound-orders/[id]/transport',
            component: () => import('/app/src/pages/outbound-orders/[id]/transport.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/pallet-search',
    /* internal name: '/pallet-search' */
    /* no component */
    children: [
      {
        path: '',
        name: '/pallet-search/',
        component: () => import('/app/src/pages/pallet-search/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/pallet-search/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/pallet-search/[id]/',
            component: () => import('/app/src/pages/pallet-search/[id]/index.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/products',
    /* internal name: '/products' */
    /* no component */
    children: [
      {
        path: '',
        name: '/products/',
        component: () => import('/app/src/pages/products/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/products/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/products/[id]/',
            component: () => import('/app/src/pages/products/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'damage-reports',
            name: '/products/[id]/damage-reports',
            component: () => import('/app/src/pages/products/[id]/damage-reports.vue'),
            /* no children */
          },
          {
            path: 'edit',
            name: '/products/[id]/edit',
            component: () => import('/app/src/pages/products/[id]/edit.vue'),
            /* no children */
          },
          {
            path: 'inbound-activity',
            name: '/products/[id]/inbound-activity',
            component: () => import('/app/src/pages/products/[id]/inbound-activity.vue'),
            /* no children */
          },
          {
            path: 'inventory',
            name: '/products/[id]/inventory',
            component: () => import('/app/src/pages/products/[id]/inventory.vue'),
            /* no children */
          },
          {
            path: 'lots',
            name: '/products/[id]/lots',
            component: () => import('/app/src/pages/products/[id]/lots.vue'),
            /* no children */
          },
          {
            path: 'outbound-activity',
            name: '/products/[id]/outbound-activity',
            component: () => import('/app/src/pages/products/[id]/outbound-activity.vue'),
            /* no children */
          },
          {
            path: 'pallets',
            name: '/products/[id]/pallets',
            component: () => import('/app/src/pages/products/[id]/pallets.vue'),
            /* no children */
          },
          {
            path: 'product-images',
            name: '/products/[id]/product-images',
            component: () => import('/app/src/pages/products/[id]/product-images.vue'),
            /* no children */
          },
          {
            path: 'transactions',
            name: '/products/[id]/transactions',
            component: () => import('/app/src/pages/products/[id]/transactions.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'new',
        name: '/products/new',
        component: () => import('/app/src/pages/products/new.vue'),
        /* no children */
      },
      {
        path: 'Products',
        name: '/products/Products',
        component: () => import('/app/src/pages/products/Products.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/reset-password',
    /* internal name: '/reset-password' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: '/reset-password/',
        component: () => import('/app/src/pages/reset-password/index.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: ':token',
        name: '/reset-password/[token]',
        component: () => import('/app/src/pages/reset-password/[token].vue'),
        /* no children */
      },
  _definePage_default_4
  )
    ],
  },
  {
    path: '/schedule',
    /* internal name: '/schedule' */
    /* no component */
    children: [
      {
        path: '',
        name: '/schedule/',
        component: () => import('/app/src/pages/schedule/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/special-orders',
    /* internal name: '/special-orders' */
    /* no component */
    children: [
      {
        path: '',
        name: '/special-orders/',
        component: () => import('/app/src/pages/special-orders/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/special-orders/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/special-orders/[id]/',
            component: () => import('/app/src/pages/special-orders/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'billable-activity',
            name: '/special-orders/[id]/billable-activity',
            component: () => import('/app/src/pages/special-orders/[id]/billable-activity.vue'),
            /* no children */
          },
          {
            path: 'files',
            name: '/special-orders/[id]/files',
            component: () => import('/app/src/pages/special-orders/[id]/files.vue'),
            /* no children */
          },
          {
            path: 'parcel-shipment',
            name: '/special-orders/[id]/parcel-shipment',
            component: () => import('/app/src/pages/special-orders/[id]/parcel-shipment.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'New',
        name: '/special-orders/New',
        component: () => import('/app/src/pages/special-orders/New.vue'),
        /* no children */
      },
      {
        path: 'upload',
        /* internal name: '/special-orders/upload' */
        /* no component */
        children: [
          {
            path: '',
            name: '/special-orders/upload/',
            component: () => import('/app/src/pages/special-orders/upload/index.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/support-tickets',
    /* internal name: '/support-tickets' */
    /* no component */
    children: [
      {
        path: '',
        name: '/support-tickets/',
        component: () => import('/app/src/pages/support-tickets/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        name: '/support-tickets/[id]',
        component: () => import('/app/src/pages/support-tickets/[id].vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/support-tickets/new',
        component: () => import('/app/src/pages/support-tickets/new.vue'),
        /* no children */
      },
  _mergeRouteRecord(
      {
        path: 'settings',
        name: '/support-tickets/settings',
        component: () => import('/app/src/pages/support-tickets/settings.vue'),
        /* no children */
      },
  _definePage_default_5
  )
    ],
  },
  {
    path: '/tenants',
    /* internal name: '/tenants' */
    /* no component */
    children: [
      {
        path: '',
        name: '/tenants/',
        component: () => import('/app/src/pages/tenants/index.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/transport-orders',
    /* internal name: '/transport-orders' */
    /* no component */
    children: [
      {
        path: '',
        name: '/transport-orders/',
        component: () => import('/app/src/pages/transport-orders/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/transport-orders/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/transport-orders/[id]/',
            component: () => import('/app/src/pages/transport-orders/[id]/index.vue'),
            /* no children */
          },
          {
            path: 'BillableActivity',
            name: '/transport-orders/[id]/BillableActivity',
            component: () => import('/app/src/pages/transport-orders/[id]/BillableActivity.vue'),
            /* no children */
          },
          {
            path: 'files',
            name: '/transport-orders/[id]/files',
            component: () => import('/app/src/pages/transport-orders/[id]/files.vue'),
            /* no children */
          },
          {
            path: 'history',
            name: '/transport-orders/[id]/history',
            component: () => import('/app/src/pages/transport-orders/[id]/history.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'loads',
        name: '/transport-orders/loads',
        component: () => import('/app/src/pages/transport-orders/loads.vue'),
        /* no children */
      },
      {
        path: 'new',
        name: '/transport-orders/new',
        component: () => import('/app/src/pages/transport-orders/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/users',
    /* internal name: '/users' */
    /* no component */
    children: [
      {
        path: '',
        name: '/users/',
        component: () => import('/app/src/pages/users/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/users/[id]' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: '/users/[id]/',
            component: () => import('/app/src/pages/users/[id]/index.vue'),
            /* no children */
          },
  _definePage_default_6
  ),
          {
            path: 'edit',
            name: '/users/[id]/edit',
            component: () => import('/app/src/pages/users/[id]/edit.vue'),
            /* no children */
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'admins',
        name: '/users/admins',
        component: () => import('/app/src/pages/users/admins.vue'),
        /* no children */
      },
  _definePage_default_7
  ),
      {
        path: 'new',
        name: '/users/new',
        component: () => import('/app/src/pages/users/new.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/warehouses',
    /* internal name: '/warehouses' */
    /* no component */
    children: [
      {
        path: '',
        name: '/warehouses/',
        component: () => import('/app/src/pages/warehouses/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/warehouses/[id]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/warehouses/[id]/',
            component: () => import('/app/src/pages/warehouses/[id]/index.vue'),
            /* no children */
          },
  _mergeRouteRecord(
          {
            path: 'dashboard',
            name: '/warehouses/[id]/dashboard',
            component: () => import('/app/src/pages/warehouses/[id]/dashboard.vue'),
            /* no children */
          },
  _definePage_default_8
  )
        ],
      }
    ],
  },
  {
    path: '/woocommerce',
    /* internal name: '/woocommerce' */
    /* no component */
    children: [
      {
        path: 'logs',
        /* internal name: '/woocommerce/logs' */
        /* no component */
        children: [
          {
            path: '',
            name: '/woocommerce/logs/',
            component: () => import('/app/src/pages/woocommerce/logs/index.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'users',
        /* internal name: '/woocommerce/users' */
        /* no component */
        children: [
          {
            path: '',
            name: '/woocommerce/users/',
            component: () => import('/app/src/pages/woocommerce/users/index.vue'),
            /* no children */
          }
        ],
      }
    ],
  }
]
