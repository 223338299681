<script setup>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";
import router from "@/router";
import { ref, onMounted, watch } from "vue";
// import SignoutWarning from "@/components/user/SignoutWarning.vue"


const layout = ref("DefaultLayout");
const renderInfo = ref({});

watch(
  () => router.currentRoute.value.meta.layout,
  (newLayout) => {
    if (newLayout) {
      layout.value = newLayout;
    } else {
      layout.value = "DefaultLayout";
    }
  },
  { immediate: true }
);
</script>

<template>
  <DefaultLayout v-if="layout == 'DefaultLayout'">
    <RouterView />
  </DefaultLayout>

  <EmptyLayout v-else-if="layout == 'EmptyLayout'">
    <RouterView />
  </EmptyLayout>
</template>
