<script setup lang="ts">
import { ref, watch } from "vue";
import styles from "./NovuNotificationStyles";
import { useUserStore } from "@/stores/user";
import { NotificationCenterComponent } from "@novu/notification-center-vue";
import { useDark } from "@vueuse/core";

const props = defineProps<{
  popoverConfig?: any;
}>();

const novuStyles = ref();

const colorScheme = ref("light");

const isDark = useDark({
  onChanged(dark) {
    if (dark) {
      colorScheme.value = "dark";
      novuStyles.value = styles.dark;
    } else {
      colorScheme.value = "light";
      novuStyles.value = styles.light;
    }
  },
});

const userStore = useUserStore();
let SUBSCRIBER_ID = "NOVU_SUBSCRIBER_ID_NOT_FOUND";

// This does not work since userStore.user is null initially and never called again 
if (userStore.user) {
  SUBSCRIBER_ID = `${import.meta.env.VITE_NOVU_DEV_ID}:tenant-${userStore.user.tenant_id}:user-${
    userStore.user.id
  }`;
}
// console.log("SUBSCRIBER_ID:" + SUBSCRIBER_ID);

const APPLICATION_IDENTIFIER =
  import.meta.env.VITE_NOVU_APP_ID ?? "NOVU_APPLICATION_IDENTIFIER_NOT_FOUND";
const CONNECT_BASE_URL = import.meta.env.VITE_CONNECT_URL;

const sessionLoaded = () => {
  console.log("Session loaded!", SUBSCRIBER_ID);
};

const notificationClicked = (message: any) => {
  console.log("Notification clicked:", message);
  const targetPath = message?.cta?.data?.url;
  if (targetPath) {
    const targetUrl = `${CONNECT_BASE_URL}${targetPath}`;
    console.log("Action:", targetUrl);
    window.open(targetUrl, targetPath);
  } else {
    console.log("message.cta.data.url not found, no link available, skipping");
  }
};

const defaultPopoverConfig = {
  position: "auto",
  offset: 0,
};

watch(() => userStore.user, (newValue) => {
    if (newValue) {
      SUBSCRIBER_ID = `${import.meta.env.VITE_NOVU_DEV_ID}:tenant-${userStore?.user?.tenant_id}:user-${
        userStore?.user?.id
      }`;
    }
  },{ deep: true }
);
</script>

<template>
  <NotificationCenterComponent
    ref="notificationCenter"
    class="notification"
    :subscriberId="SUBSCRIBER_ID"
    :colorScheme="isDark ? 'dark' : 'light'"
    :applicationIdentifier="APPLICATION_IDENTIFIER"
    :styles="novuStyles"
    :sessionLoaded="sessionLoaded"
    :notificationClicked="notificationClicked"
    v-slot="slot"
    :popoverConfig="props.popoverConfig || defaultPopoverConfig"
    vslot="vslot"
  >
    <slot></slot>
  </NotificationCenterComponent>
</template>
