import { defineStore } from 'pinia';

export const useThemeStore = defineStore('themeStore', {
  state: () => ({
    agTheme: localStorage.getItem('agTheme') || "ag-theme-material",
    theme: localStorage.getItem('theme') || "light",
  }),
  actions: {
    updateTheme(theme: string) {
      if (theme === "dark") {
        this.agTheme = "ag-theme-material-auto-dark";
        this.theme = "dark";
      } else {
        this.agTheme = "ag-theme-material";
        this.theme = "light";
      }

      // Store the theme in localStorage
      localStorage.setItem('theme', this.theme);
      localStorage.setItem('agTheme', this.agTheme);
    },
  },
});
