import { defineStore } from 'pinia';
import c3api from '../c3api';
import { useToast } from 'vue-toastification';

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: null as User | null,
    toast: useToast()
  }),
  actions: {
    // Explicitly type the return of fetchUser as a Promise<void> to reflect that it doesn't return a value
    async fetchUser(): Promise<void> {
      return c3api
        .get<{ data: User }>('/my/user')
        .then((response) => {
          this.user = response.data.data;
        })
        .catch((error) => {
          this.toast.error(error.response.data.data.join('. '));
          localStorage.removeItem('token');
        });
    },
    reset() {
      // Reset the state
      this.user = null;
    },
    logout() {
      this.reset();
      localStorage.removeItem('token');
    }
  },
  getters: {
    // Ensure the getter correctly returns a boolean value
    hasPermissionTo: (state) => {
      return (permission: string): boolean => {
        // @ts-ignore
        return !!state.user?.permissions[permission];
      };
    },
    isSupportTicketAdmin: (state) => {
      return (): boolean => {
        if (!state.user) return false;
        return state.user && state.user.permissions.support_ticket_role == "superadmin" || state.user.permissions.support_ticket_role == "tenant_admin";
      }
    },
    hasSupportTicketRole: (state) => {
      return (role: string): boolean => {
        if (!state.user) return false;
        return state.user && state.user.permissions.support_ticket_role == role;
      }
    }
  }
});
